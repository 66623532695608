
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import { Form, Field } from "vee-validate";
import { checkNewEmail, checkNewEmailRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { useStore } from "@/store";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "ChangeEmail",
    components: {
        Form,
        Field
    },
    setup() {
        const store = useStore();
        const sidClient = useSidClient();
        const router = useRouter();
        const userBaseItem = UserBaseItem.inject();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const currentEmailRef = ref("");
        const newEmailRef = ref("");
        const newEmailRetypeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            newEmail: checkNewEmail,
            newEmailRetype: checkNewEmailRetype
        }); 

        /**
         * 変更ボタン押下時処理
         * @async
         */
        const changeEmail = async () => {
            // メールアドレス変更
            await sidClient.changeEmail(newEmailRef.value)
                .then(() => {
                    // メールアドレス変更確認画面へ遷移
                    router.push({ name: "ChangeEmailConfirm", query: { email: newEmailRef.value } });
                })
                .catch(reason => {
                    if (reason.message === SID_ERROR_MSG_NOT_AUTHENTICATED || reason.message === SID_ERROR_MSG_UNAUTHORIZED) {
                        // ログイン画面へ遷移
                        router.push({ name: "Login" });
                    } else if (reason.response?.data === "the email has already been changed") {
                        errorMsgRef.value = "現在のメールアドレスとは異なるメールアドレスをご入力ください。";
                    } else if (reason.response?.data === "the email address is not allowed") {
                        errorMsgRef.value = "このメールアドレスへの変更はできません。";
                    } else {
                        errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                    }
                });
        };

        onMounted(async () => {
            try {
                // 基本属性情報再取得して保持
                await store.dispatch("fetchUserInfo");
                userBaseItem.setUserInfo(store.state.userInfo);
            } catch (e) {
                // 特に何もしない
            }
            currentEmailRef.value = store.state.userInfo.baseInfo.email;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            currentEmailRef,
            newEmailRef,
            newEmailRetypeRef,
            errorMsgRef,
            schema,
            changeEmail,
            router
        };
    },
});
