
import { defineComponent } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import useDeterring from "@/commons/useDeterring";


export default defineComponent({
    name: "LinkageChangeEmailComplete",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const { linkageInfo, fetchToken, routeNames, siteDefaultCallbackUrl, isNeedCallbackWithToken } = LinkageItem.inject();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const callbackToken = () => {
            if (!isNeedCallbackWithToken()) {
                window.location.href = siteDefaultCallbackUrl as string;
            } else {
                window.location.href = linkageInfo.callbackUrlWithToken as string;
            }
        };

        asyncDeterringWrap(fetchToken, this, sidClient)
            .then(() => {
                // メールアドレス変更時はクッキーの認証情報をクリアするためログアウト
                sidClient.signOut();
                // 画面遷移
                callbackToken();
            })
            .catch(error => {
                // メールアドレス変更時はクッキーの認証情報をクリアするためログアウト
                sidClient.signOut();
                // エラー画面遷移
                router.push({ name: routeNames.GENERAL_ERROR, params: { message: "認証処理に失敗しました。" } });
            });

        return {
            isDeterringRef,
            callbackToken,
        };
    }
});
