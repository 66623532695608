
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { PREFECTURE_LIST } from "@/commons/constants";
import { Field, Form } from "vee-validate";
import {
    checkAddressIsReqd,
    checkBirthdayIsReqd,
    checkBloodTypeNotReqd,
    checkBuildingNotReqd,
    checkFirstKanaIsReqd,
    checkFirstNameIsReqd,
    checkGenderIsReqd,
    checkHasChildNotReqd,
    checkIsMarriedNotReqd,
    checkLastKanaIsReqd,
    checkLastNameIsReqd,
    checkNicknameIsReqd,
    checkPrefectureIsReqd,
    checkTelIsReqd,
    checkZipCodeIsReqd
} from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { useSidClient } from "@/plugins/auth";
import moment from "moment";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { useDateForm } from "@/commons/dateForm";
import { UserHpplusAcademia } from "@/commons/interfaces";
import { checkMailMagazineIsReqd } from "@/commons/inputCheckSiteUniqueInfo";
import LinkageTerms from "@/components/Linkage/LinkageTerms.vue";

const JOB_LIST = [
    "高校生以下",
    "専門学校生・短大生",
    "大学生・大学院生",
    "専業主婦・主夫",
    "年金受給者",
    "パート・アルバイト",
    "会社員（正社員）",
    "会社員（契約社員）",
    "会社員（派遣社員）",
    "会社経営・役員",
    "公務員",
    "専門職（医師・看護師、弁護士、税理士、社会労務士などの士業）",
    "NGO／NPO法人職員",
    "自営業",
    "自由業（フリーランス）",
    "無職"
];

const INTEREST_LIST = [
    "ファッション",
    "美容",
    "健康（ヘルスケア・メンタルヘルスなど）",
    "スポーツ／フィットネス（ヨガ・筋トレ・各種運動）",
    "語学",
    "キャリア／副業",
    "ビジネススキルアップ",
    "資格取得",
    "マネー／財テク",
    "マナー／教養",
    "グルメ／お酒",
    "旅行／アウトドア",
    "クリエイティブ（映像、写真など）",
    "クリエイティブ（編集、デザインなど）",
    "クリエイティブ（料理、手芸・工芸、DIYなど）",
    "ライフスタイル（住宅、インテリア・収納など）",
    "ライフスタイル（アロマ、植物、オーガニックなど）",
    "エンターテインメント（演劇・舞台、映画など）",
    "エンターテインメント（音楽、ダンスなど）",
    "エンターテインメント（コミック・アニメなど）",
    "文化／芸術（伝統芸能、美術など）",
    "占い／スピリチュアル",
    "社会貢献／SDGｓ",
    "恋愛／出会い／結婚",
    "子育て／教育",
    "人間関係／家族",
    "加齢・老後対策"
];

export default defineComponent({
    name: "HapiacaProfile",
    components: {
        Form,
        Field,
        LinkageTerms
    },
    props: {
        // 次画面の遷移先
        nextRoute: { type: String, required: true },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const sidClient = useSidClient();
        const { setYear, setDate, date, years, months, dates, birthday } = useDateForm();
        const { linkageInfo, routeNames } = LinkageItem.inject();

        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        const prefectureList = PREFECTURE_LIST;

        /** メルマガ配信表示一覧 */
        const MAIL_MAGAZINE_ENABLE_LIST = [
            { routeName: `${routeNames.LOGIN_PROFILE}Child`, enable: true },
            { routeName: `${routeNames.LOGIN_PROFILE_CONFIRM}Child`, enable: true },
            { routeName: `${routeNames.SIGNUP_PROFILE}Child`, enable: true },
            { routeName: `${routeNames.SIGNUP_CONFIRM}Child`, enable: true },
            { routeName: `${routeNames.PROFILE}Child`, enable: false },
            { routeName: `${routeNames.PROFILE_CONFIRM}Child`, enable: false },
            { routeName: `${routeNames.CHANGE_EMAIL_RESCUE_PROFILE}Child`, enable: true },
            { routeName: `${routeNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM}Child`, enable: true },
            { routeName: `${routeNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child`, enable: true },
            { routeName: `${routeNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM}Child`, enable: true },
            { routeName: `${routeNames.MAIL_MAGAZINE_RESCUE_PROFILE}Child`, enable: true },
            { routeName: `${routeNames.MAIL_MAGAZINE_RESCUE_PROFILE_CONFIRM}Child`, enable: true }
        ];

        const mailMagazineEnable = MAIL_MAGAZINE_ENABLE_LIST.find(x => x.routeName === route.name)?.enable;

        const userBase = userBaseItem.userData;
        const userHapiaca = siteUniqItem.userData as UserHpplusAcademia;

        const formRef = ref<any>();
        // 初回サービス登録判定
        const firstServiceReg = route.name === `${routeNames.LOGIN_PROFILE}Child` && !isSiteRegistered(linkageInfo.linkageId, userBase);


        // 新規会員登録時の誕生日初期選択値
        if (!userBase.identityId) {
            setYear(1990);
        }

        const moveConfirm = async () => {
            userBaseItem.storeStorage();
            siteUniqItem.storeStorage();
            await router.push({ name: props.nextRoute });
        };

        const schema = Yup.object().shape({
            lastName: checkLastNameIsReqd,
            firstName: checkFirstNameIsReqd,
            lastKana: checkLastKanaIsReqd,
            firstKana: checkFirstKanaIsReqd,
            nickname: checkNicknameIsReqd,
            gender: checkGenderIsReqd,
            birthday: checkBirthdayIsReqd,
            zipCode: checkZipCodeIsReqd,
            prefecture: checkPrefectureIsReqd,
            address: checkAddressIsReqd,
            building: checkBuildingNotReqd,
            tel: checkTelIsReqd,
            isMarried: checkIsMarriedNotReqd,
            hasChild: checkHasChildNotReqd,
            bloodType: checkBloodTypeNotReqd,
        });
        // メルマガを表示する場合はバリデーションを追加
        if (mailMagazineEnable) {
            schema.shape({ mailMagazineMain: checkMailMagazineIsReqd });
        }

        /**
         * 住所自動入力
         */
        const addressAutoFill = () => {
            const zipCode = userBase.baseInfo.zipCode ? userBase.baseInfo.zipCode : undefined;
            new YubinBangoCore(zipCode, (addr: any) => {
                userBase.baseInfo.prefecture = addr.region; // 都道府県
                userBase.baseInfo.address = addr.locality; // 市区町村
                userBase.baseInfo.address += addr.street; // 町域
            });
        };

        const birthdayValidate = () => {
            formRef.value.validateField("birthday");
        };

        onMounted(async () => {
            // 誕生日をセット
            setDate(userBase.commonAttrInfo.birthday);

            // 基本属性情報の有無を判断
            if (userBase.identityId) {              
                // ニックネーム
                userBase.commonAttrInfo.nickName = (userBase.commonAttrInfo.nickName || "")
                    .replaceAll("&lt;", "<")
                    .replaceAll("&gt;", ">");

                const ret = await formRef.value.validate();
                // vee-validate 4.6.7 以降、slotのerrorsと連動していないため、setする
                formRef.value.setErrors(ret.errors);
            } else {
                // メールアドレスをセット
                try {
                    const userData = await sidClient.getUserData();
                    const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                    userBase.baseInfo.email = email;
                } catch (e) {
                    await router.push({
                        name: routeNames.SIGNUP_IDENTIFICATION,
                        query: { retUrl: router.currentRoute.value.name as string },
                        params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" },
                    });
                }
            }

            watchEffect(() => {
                const tmpDate = moment(`${date.year}-${date.month}-${date.day}`, "YYYY-M-D", true);
                userBase.commonAttrInfo.birthday = tmpDate.isValid() ? tmpDate.format("YYYY-MM-DD") : null;
            });
        });

        return {
            userBase,
            userHapiaca,
            date,
            birthday,
            years,
            months,
            dates,
            prefectureList,
            JOB_LIST,
            INTEREST_LIST,
            schema,
            mailMagazineEnable,
            addressAutoFill,
            moveConfirm,
            birthdayValidate,
            firstServiceReg,
            formRef,
        };
    },
});
