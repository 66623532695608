
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { defineComponent, inject, onMounted, ref } from "vue";
import { getSiteRegistration } from "@/commons/commonUtility";
import { LeaveNoteItem, LeaveNoteItemKey } from "@/modules/leaveNote";
import { SiteInfo } from "@/commons/interfaces";

export default defineComponent({
    name: "Withdrawal",
    setup() {
        const router = useRouter();
        const store = useStore();
        const { registeredSiteList } = getSiteRegistration(store.state.siteLinkage, store.state.userInfo);

        const { leaveNote } = inject(LeaveNoteItemKey) as LeaveNoteItem;
        const isDispFormRef = ref(true);

        /**
         * 退会ボタン押下時処理
         * @async
         */
        const withdrawal = () => {
            router.push({ name: "WithdrawalConfirm" });
        };

        onMounted(async () => {
            // 登録中サービス有無による画面表示判断
            // 登録中サービスがあれば、退会フォーム非表示
            isDispFormRef.value = !(store.state.userInfo.siteInfo.filter((s: SiteInfo) => s.enable).length > 0);
        });

        return {
            leaveNote,
            isDispFormRef,
            registeredSiteList,
            withdrawal,
            router
        };
    },
});
