import * as Yup from "yup";
import { RIMACOMA_ID_FORBIDDEN_STR_LIST } from "./linkage/sites/rimacomi";

/* メモ */
/* このファイルでは、サイト固有情報のチェック内容を定義する */
/* 明示的にnullを認めている場合、必須項目だとしても.nullable()を付与する */

/* HAPPY PLUS ACADEMIA */
/* 必須 */
export const checkMailMagazineIsReqd = Yup.boolean()
    .nullable()
    .required("メールマガジンは、必須項目です。");
/* 任意 */
export const checkMailMagazineNotReqd = Yup.boolean()
    .nullable();


/* LEE */
/* 必須 */
export const checkLeeJobIsReqd = Yup.string()
    .nullable()
    .required("職業は、必須項目です。");
export const checkLeeNickNameIsReqd = Yup.string()
    .nullable()
    .max(128, "ニックネーム（LEE用）は、128文字以内で入力してください。")
    .required("ニックネーム（LEE用）は、必須項目です。");
export const checkLeeIsSendMailMagazineIsReqd = Yup.boolean()
    .nullable()
    .required("LEEからのメールマガジンは、必須項目です。");
/* 任意 */
export const checkLeeJobNotReqd = Yup.string()
    .nullable();
export const checkLeeNickNameNotReqd = Yup.string()
    .nullable()
    .max(128, "ニックネーム（LEE用）は、128文字以内で入力してください。");
export const checkLeeIsSendMailMagazineNotReqd = Yup.boolean()
    .nullable();

/* マンガ祭 */
/* サイト固有情報なし */

/* UOMO */
/* 必須 */
export const checkUomoJobIsReqd = Yup.number()
    .nullable()
    .required("就業形態は、必須項目です。");
export const checkUomoBusinessTypeIsReqd = Yup.number()
    .nullable()
    .required("業種は、必須項目です。");
export const checkUomoIncomeIsReqd = Yup.number()
    .nullable()
    .required("収入は、必須項目です。");
export const checkUomoDrivingLicenceIsReqd = Yup.number()
    .nullable()
    .required("自動車運転免許の有無は、必須項目です。");
export const checkUomoIsSmokingIsReqd = Yup.boolean()
    .nullable()
    .required("喫煙は、必須項目です。");
export const checkUomoDrinkingIsReqd = Yup.number()
    .nullable()
    .required("飲酒は、必須項目です。");
export const checkUomoIsSendMailMagazineIsReqd = Yup.boolean()
    .nullable()
    .required("メルマガ受信は、必須項目です。");
/* 任意 */
export const checkUomoJobNotReqd = Yup.number()
    .nullable();
export const checkUomoBusinessTypeNotReqd = Yup.number()
    .nullable();
export const checkUomoIncomeNotReqd = Yup.number()
    .nullable();
export const checkUomoDrivingLicenceNotReqd = Yup.number()
    .nullable();
export const checkUomoIsSmokingNotReqd = Yup.boolean()
    .nullable();
export const checkUomoDrinkingNotReqd = Yup.number()
    .nullable();
export const checkUomoIsSendMailMagazineNotReqd = Yup.boolean()
    .nullable();

/* リマコミ＋ */
/* 必須 */
export const checkRimacomiRimacomiIdIsReqd = Yup.string()
    .nullable()
    .max(32, "リマコミ＋IDは、32文字以内で入力してください。")
    .matches(/^[A-Za-z0-9_]*$/, "リマコミ＋IDには、半角英数字とアンダースコアのみ使用できます。")
    .test(
        "forbiddenStr",
        "この文字列は、リマコミ＋IDとして使用できません。",
        (value) => {
            return RIMACOMA_ID_FORBIDDEN_STR_LIST.filter(regExpStr => value?.match(regExpStr)).length === 0;
        }
    )
    .required("リマコミ＋IDは、必須項目です。");
/* 任意 */
export const checkRimacomiRimacomiIdNotReqd = Yup.string()
    .nullable()
    .max(32, "リマコミ＋IDは、32文字以内で入力してください。")
    .matches(/^[A-Za-z0-9_]$/, "リマコミ＋IDには、半角英数字とアンダースコアのみ使用できます。")
    .test(
        "forbiddenStr",
        "この文字列は、リマコミ＋IDとして使用できません。",
        (value) => {
            return RIMACOMA_ID_FORBIDDEN_STR_LIST.filter(regExpStr => value?.match(regExpStr)).length === 0;
        }
    );

/* メンズマガジン */
/* 必須 */
export const checkMensMagazineJobIsReqd = Yup.number()
    .nullable()
    .required("職業・業種は、必須項目です。");
export const checkMensMagazineIncomeIsReqd = Yup.number()
    .nullable()
    .required("収入は、必須項目です。");
export const checkMensMagazineDrivingLicenceIsReqd = Yup.number()
    .nullable()
    .required("自動車運転免許の有無は、必須項目です。");
export const checkMensMagazineIsSmokingIsReqd = Yup.boolean()
    .nullable()
    .required("喫煙は、必須項目です。");
export const checkMensMagazineDrinkingIsReqd = Yup.number()
    .nullable()
    .required("飲酒は、必須項目です。");
export const checkMensMagazineMailMagazineIsReqd = Yup.boolean()
    .nullable()
    .required("メルマガ受信は、必須項目です。");
/* 任意 */
export const checkMensMagazineJobNotReqd = Yup.number()
    .nullable();
export const checkMensMagazineBusinessTypeNotReqd = Yup.number()
    .nullable();
export const checkMensMagazineIncomeNotReqd = Yup.number()
    .nullable();
export const checkMensMagazineDrivingLicenceNotReqd = Yup.number()
    .nullable();
export const checkMensMagazineIsSmokingNotReqd = Yup.boolean()
    .nullable();
export const checkMensMagazineDrinkingNotReqd = Yup.number()
    .nullable();
export const checkMensMagazineIsSendMailMagazineNotReqd = Yup.boolean()
    .nullable();

/* 集英社オンライン */
/* 必須 */
export const checkShueishaOnlineIsSendMailMagazineReqd = Yup.boolean()
    .nullable()
    .required("メルマガ受信は、必須項目です。");

/* Vジャンプ */
/* 必須 */
export const checkVJumpIsSendMailMagazineReqd = Yup.boolean()
    .nullable()
    .required("メルマガ受信は、必須項目です。");
