import { SidUserClient } from "@sid/sid_client";
import { InjectionKey } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";

export interface UserAttribute {
    identityId: string | null;
    userName: string | null;
}

export class BaseInfo {
    lastName: string | null = null
    firstName: string | null = null
    lastKana: string | null = null
    firstKana: string | null = null
    zipCode: string | null = null
    prefecture: string | null = null
    address: string | null = null
    building: string | null = null
    email: string | null = null
    tel: string | null = null
}

export class ShippingInfo {
    tag: string | null = null
    lastName: string | null = null
    firstName: string | null = null
    lastKana: string | null = null
    firstKana: string | null = null
    zipCode: string | null = null
    prefecture: string | null = null
    address: string | null = null
    building: string | null = null
    tel: string | null = null
}

export class SiteInfo {
    siteLinkageId: number | null = null
    enable: boolean | null = null
    regDate: string | null = null
    leaveDate: string | null = null
    leaveNote1?: string | null = null
    leaveNote2?: string | null = null
}

export class CommonAttrInfo {
    gender: string | null = null
    birthday: string | null = null
    imagePath: string | null = null
    nickName: string | null = null
    isMarried: boolean | null = null
    hasChild: boolean | null = null
    bloodType: string | null = null
}

export class MemberAgreementInfo {
    version: number | null = null
    regDate: string | null = null
}

export class ManageInfo {
    regBy: string | null = null
    regDate: string | null = null
    updBy: string | null = null
    updDate: string | null = null
    deleted = false
    version: number | null = null
}

/**
 * 基本属性情報
 */
export class UserBase implements UserAttribute {
    identityId: string | null = null
    userName: string | null = null
    baseInfo: BaseInfo = new BaseInfo()
    shippingInfo: ShippingInfo[] = []
    siteInfo: SiteInfo[] = []
    commonAttrInfo: CommonAttrInfo = new CommonAttrInfo()
    memberAgreeInfo: MemberAgreementInfo = new MemberAgreementInfo()
    manageInfo: ManageInfo = new ManageInfo()
}

export class MailMagazineHpplusOne {
	mailMagazineId: number | null = null;
	name: string | null = null;
	regDate: string | null = null;
	leaveDate: string | null = null;
}

/**
 * サイト固有情報（ハピプラ）
 */
export class UserHpplusOne implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    mailmagazine: MailMagazineHpplusOne[] = [];
    job: string | null = null;
    hid: number | null = null;
    manageInfo: ManageInfo = new ManageInfo();
}

export class MailMagazineHpplusAcademia {
    main: boolean | null = true;
}

/**
 * サイト固有情報（ハピアカ）
 */
export class UserHpplusAcademia implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    job: string | null = null;
    interest: string[] = [];
    mailMagazine: MailMagazineHpplusAcademia | null = new MailMagazineHpplusAcademia();
    manageInfo: ManageInfo = new ManageInfo();
}

export class ServiceAgreeInfoLee {
    version: number | null = null
    regDate: string | null = null
}

/**
 * サイト固有情報（LEE）
 */
export class UserLee implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    job: string | null = null;
    nickName: string | null = null;
    isSendMailMagazine: boolean | null = null;
    serviceAgreeInfo: ServiceAgreeInfoLee = new ServiceAgreeInfoLee();
    manageInfo: ManageInfo = new ManageInfo();
}

/**
 * サイト固有情報（UOMO）
 */
export class UserUomo implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    job: number | null = null;
    businessType: number | null = null;
    income: number | null = null;
    drivingLicence: number | null = null;
    isSmoking: boolean | null = null;
    drinking: number | null = null;
    isSendMailMagazine: boolean | null = null;
    manageInfo: ManageInfo = new ManageInfo();
}

/**
 * サイト固有情報（リマコミ＋）
 */
export class UserRimacomi implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    rimacomiId: string | null = null;
    manageInfo: ManageInfo = new ManageInfo();
}

/**
 * サイト固有情報（メンズマガジン）
 */
export class MailMagazineMensMagazine {
    weeklyPlayboy: boolean | null = false;
    webSportiva: boolean | null = false;
    mensNonNo: boolean | null = false;
}

export class UserMensMagazine implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    job: number | null = null;
    income: number | null = null;
    drivingLicence: number | null = null;
    isSmoking: boolean | null = null;
    drinking: number | null = null;
    hobby: string[] | null = [];
    hobbyOther: string | null = null;
    interestSports: string[] | null = [];
    spendHolidays: string[] | null = [];
    favoriteBrands: string[] | null = [];
    favoriteBrandOther: string | null = null;
    favoriteCosmes: string[] | null = [];
    favoriteHairCares: string[] | null = [];
    mailMagazine: MailMagazineMensMagazine | null = new MailMagazineMensMagazine();
    manageInfo: ManageInfo = new ManageInfo();
}

/**
 * サイト固有情報（集英社オンライン）
 */
export class UserShueishaOnline implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    isSendMailMagazine: boolean | null = true;
    manageInfo: ManageInfo = new ManageInfo();
}

/**
 * サイト固有情報（VJump）
 */
export class UserVJump implements UserAttribute {
    identityId: string | null = null;
    userName: string | null = null;
    isSendMailMagazine: boolean | null = true;
    manageInfo: ManageInfo = new ManageInfo();
}

export type LinkageChild = {
    linkage_sub_id: number | null;
    name?: string | null;
    name_jp?: string | null;
    url?: string | null;
    login_url?: string | null;
    register_url?: string | null;
    withdrawal_url?: string | null;
    description?: string | null;
    is_close?: boolean | null;
    is_stand_alone?: boolean | null;
}


/**
 * 連携サイト情報
 */
export class SiteLinkage {
    link_id: number | null = null;
    name: string | null = null;
    name_jp: string | null = null;
    kind: string | null = null;
    url?: string | null = null;
    login_url?: string | { ios: string; android: string } | null = null;
    register_url?: string | null = null;
    withdrawal_url?: string | { ios: string; android: string } | null = null;
    description?: string | null = null;
    is_close?: boolean | null = null;
    is_stand_alone?: boolean | null = null;
    children?: LinkageChild[] | null = null;
    base_path: string | null = null;
    site_code: string | null = null;
}

/**
 * 連携画面ユーザ情報管理インターフェース
 * <pre>
 * 連携画面で扱うユーザ情報管理クラスで実装する
 * </pre>
 */
export interface LinkageUserData<T extends UserAttribute> {
    /**
     * Vueでinject()する際のキー
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<any>>>;

    /**
     * ユーザ情報の取得格納
     * @param sidClient
     */
    fetch(sidClient: SidUserClient): Promise<void>;

    /**
     * このインスタンスへのデータコピー
     * @param obj
     */
    copy(obj: T): void;

    /**
     * ユーザ情報取得
     */
    getData(): T;

    /**
     * 必須項目チェック処理
     */
    checkRequired(): boolean;

    /**
     * ユーザ情報初期化
     */
    initData(): void;
}

/**
 * 選択肢項目情報
 */
export interface ChoiceInfo<T extends string | number | boolean> {
    value: T | null;
    text: string;
}
