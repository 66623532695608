import { App, inject, InjectionKey } from "vue";
import { SidClientConfig, SidUserClient } from "@sid/sid_client";

/** インジェクションキー */
const SidClientKey: InjectionKey<SidUserClient> = Symbol("SidClient");
/** SIDクライアントインスタンス */
export let sidClient: SidClientVue;

/**
 * SIDクライアントプラグイン
 */
class SidClientVue extends SidUserClient {

    constructor(clientConfig: SidClientConfig) {
        super(clientConfig);
    }

    install(app: App): void {
        // app.config.globalProperties.$sid = this;
        app.provide(SidClientKey, this);
    }

    async takeOverClientId(clientId: string) {
        // クライアントIDを引き継ぎ
        this.clientConfig.authInfo.clientId = clientId;
        const newInstance = Reflect.construct(SidClientVue, [this.clientConfig], this.constructor);
        await newInstance.waitInitialized();
        Object.assign(this, newInstance);
    }
}

/** SIDクライアントインスタンス生成 */
export function createSidClient(clientConfig: SidClientConfig) {
    sidClient = new SidClientVue(clientConfig);
}

/** SIDクライアントインスタンス取得 */
export function useSidClient(): SidClientVue {
    return inject(SidClientKey) as SidClientVue;
}
