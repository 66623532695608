
import { defineComponent, onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed, isSiteRegistered } from "@/commons/commonUtility";
import { useSidClient } from "@/plugins/auth";
import { useStore } from "@/store";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { ImageItemKey, createImageItem } from "@/modules/imageInfo";

export default defineComponent({
    name: "LinkageUserParent",
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const sidClient = useSidClient();

        const { linkageInfo, routeNames, isNeedCallbackWithToken } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        // プロフィール画像項目の有無に関係なく、provide()を行う
        const imageItem = createImageItem();
        provide(ImageItemKey, imageItem);

        const waitViewRef = ref(false);

        // emailの指定が無い場合はエラー画面へ
        if (isNeedCallbackWithToken() && !linkageInfo.emailParamNotRequired && !linkageInfo?.email) {
            router.push({ name: routeNames.GENERAL_ERROR, params: { message: "URLに不備があります" } });
            return {
                waitViewRef
            };
        }

        onMounted(async () => {
            try {
                if (isNeedCallbackWithToken()) {
                    // SIDログインアカウントとサービスサイトのアカウントが一致するか判定
                    const userData = await sidClient.getUserData();
                    const sidLoginAccount = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                    const linkageLoginAccount = linkageInfo?.email;

                    // SID側で異なるアカウントでログインしている場合
                    if (sidLoginAccount !== linkageLoginAccount) {
                        // 本人確認画面へ遷移
                        await router.push({
                            name: routeNames.IDENTIFICATION,
                            query: { retUrl: route.name as string },
                        });
                        return;
                    }
                }

                // 基本属性情報、サイト固有情報取得
                await Promise.all([
                    userBaseItem.fetchUserData(sidClient),
                    siteUniqItem.fetchUserData(sidClient)
                ]);
                imageItem.image.path = userBaseItem.userData.commonAttrInfo.imagePath;
                userBaseItem.storeStorage();
                siteUniqItem.storeStorage();

                // サイト登録有無チェック
                if (!isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData)) {
                    const siteName = store.state.siteLinkage.find((site: any) => site.link_id === linkageInfo.linkageId)?.name;
                    await router.push({ name: routeNames.GENERAL_ERROR, params: { message: `${siteName}のサービスが登録されていません。` } });
                    return;
                }

                if (route.name !== routeNames.WITHDRAWAL) {
                    // サイト毎の必須情報チェック
                    if (!userBaseItem.checkRequired() || !siteUniqItem.checkRequired()) {
                        switch (route.name) {
                            case routeNames.CHANGE_PASSWORD:
                            case routeNames.CHANGE_PASSWORD_COMPLETE:
                            case routeNames.CHANGE_PASSWORD_RESCUE_PROFILE:
                            case `${routeNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child`:
                            case routeNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM:
                            case `${routeNames.CHANGE_PASSWORD_RESCUE_PROFILE_CONFIRM}Child`:
                                await router.replace({ name: `${routeNames.CHANGE_PASSWORD_RESCUE_PROFILE}Child` });
                                return;
                            case routeNames.CHANGE_EMAIL:
                            case routeNames.CHANGE_EMAIL_CONFIRM:
                            case routeNames.CHANGE_EMAIL_COMPLETE:
                            case routeNames.CHANGE_EMAIL_RESCUE_PROFILE:
                            case `${routeNames.CHANGE_EMAIL_RESCUE_PROFILE}Child`:
                            case routeNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM:
                            case `${routeNames.CHANGE_EMAIL_RESCUE_PROFILE_CONFIRM}Child`:
                                await router.replace({ name: `${routeNames.CHANGE_EMAIL_RESCUE_PROFILE}Child` });
                                return;
                            case routeNames.MAIL_MAGAZINE:
                            case routeNames.MAIL_MAGAZINE_CONFIRM:
                            case routeNames.MAIL_MAGAZINE_COMPLETE:
                            case routeNames.MAIL_MAGAZINE_RESCUE_PROFILE:
                            case `${routeNames.MAIL_MAGAZINE_RESCUE_PROFILE}Child`:
                            case routeNames.MAIL_MAGAZINE_RESCUE_PROFILE_CONFIRM:
                            case `${routeNames.MAIL_MAGAZINE_RESCUE_PROFILE_CONFIRM}Child`:
                                await router.replace({ name: `${routeNames.MAIL_MAGAZINE_RESCUE_PROFILE}Child` });
                                return;
                            default:
                                await router.replace({ name: `${routeNames.PROFILE}Child` });
                                return;
                        }
                    }
                }
            } catch
            (e) {
                if (isAuthFailed(e)) {
                    if (linkageInfo.email) {
                        // 本人確認画面へ遷移
                        await router.replace({ name: routeNames.IDENTIFICATION, query: { retUrl: route.name as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    } else {
                        // ログイン画面へ遷移
                        await router.replace({ name: routeNames.LOGIN, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    }
                    return;
                } else {
                    await router.push({ name: routeNames.GENERAL_ERROR });
                }
            } finally {
                waitViewRef.value = true;
            }
        });

        return {
            waitViewRef
        };
    },
});
