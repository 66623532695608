
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isSiteRegistered } from "@/commons/commonUtility";
import { loginStatus } from "@/modules/loginStatus";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import LinkageTerms from "@/components/Linkage/LinkageTerms.vue";

export default defineComponent({
    name: "LinkageLogin",
    components: {
        LinkageTerms
    },
    props: {
        message: { type: String },
    },
    setup(props) {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, setNotRelease, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames, isNeedCallbackWithToken } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        /* Reactive */
        const emailRef = ref("");
        const passwordRef = ref("");
        const disableAutoLoginRef = ref(false);
        const autoLoginRef = ref(true);
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");
        const isDisp = ref(true);

        const passwordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        // ログイン画面契機か本人確認画面契機かの判断でlinkageInfo.emailを使用するため、ログイン画面に遷移した時点でlinkageInfo.emailを空にする
        linkageInfo.email = "";

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * 次画面遷移
         * @async
         */
        const moveNext = async (userName: string) => {

            // 基本属性情報取得
            const promiseUserBase = userBaseItem.fetchUserData(sidClient);
            // サイト固有情報取得
            const promiseSiteUniq = siteUniqItem.fetchUserData(sidClient);
            // 待ち合わせ
            await Promise.all([promiseUserBase, promiseSiteUniq]);

            // ユーザ名格納
            userBaseItem.userData.userName = userName;
            siteUniqItem.userData.userName = userName;

            // セッションストレージ格納
            userBaseItem.storeStorage();
            siteUniqItem.storeStorage();

            // サイト登録有無・必須情報チェック
            if (!isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData)
                    || !userBaseItem.checkRequired()
                    || !siteUniqItem.checkRequired()) {
                await router.push({ name: `${routeNames.LOGIN_PROFILE}Child` });
                return;
            }

            // コールバック不要の場合、プロフィール画面へ遷移
            if (!isNeedCallbackWithToken()) {
                await router.push({ name: `${routeNames.PROFILE}Child` });
                return;
            }

            // トークンをコールバックする
            await fetchToken(sidClient);
            setNotRelease();
            window.location.href = linkageInfo.callbackUrlWithToken as string;
        };

        /**
         * ログイン
         * @async
         */
        const login = async () => {
            try {
                // 念の為サインアウト
                await sidClient.signOut();
                loginStatus.value = false;

                // セッションストレージ削除
                userBaseItem.removeStorage();
                siteUniqItem.removeStorage();

                // 自動ログイン無効化判断
                if (disableAutoLoginRef.value) {
                    autoLoginRef.value = false;
                }

                // サインイン
                let userName;
                try {
                    const result = await sidClient.signIn(emailRef.value, passwordRef.value, autoLoginRef.value);
                    if (result === "NEW_PASSWORD_REQUIRED") {
                        // 管理画面でアカウント登録された場合、または、管理画面でパスワード変更を行った場合
                        await router.push({
                            name: routeNames.NEW_PASSWORD,
                            query: { retUrl: returnUrlRef.value }
                        });
                        return;
                    } else if (result === "RESET_PASSWORD_REQUIRED") {
                        // Cognitoでパスワード変更された場合
                        await router.push({
                            name: routeNames.SET_PASSWORD,
                            // eslint-disable-next-line
                            query: {reset_email: emailRef.value}
                        });
                        return;
                    }
                    userName = (await sidClient.getUserData()).Username;
                } catch (e) {
                    if (e.code == "UserNotConfirmedException") {
                        // 実店舗ユーザ（ユーザ名でログイン）の場合のみだが、
                        // 実店舗ユーザは必ず確認済みのステータスとなる仕様のため、デッドコード
                        errorMsgRef.value = "ログインできませんでした。";
                    } else {
                        errorMsgRef.value = "ログインできませんでした。メールアドレスとパスワードをご確認ください。";
                    }
                    return;
                }

                // 次画面遷移処理
                await moveNext(userName);

            } catch (e) {
                await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
            }
        };

        onMounted(async () => {
            returnUrlRef.value = route.query.retUrl as string;
            errorMsgRef.value = props.message as string;
        });

        // 遷移元のログイン状態を維持する場合
        if (route.query.keepLogin) {
            // ちらつき防止
            isDisp.value = false;
            (async () => {
                // 遷移元のClientIdを引き継ぐ
                await sidClient.takeOverClientId(route.query.keepLogin as string);
                if (await sidClient.isAuthenticated()) {
                    const userName = (await sidClient.getUserData()).Username;
                    await moveNext(userName);
                }
                isDisp.value = true;
            })();
        }

        return {
            isDeterringRef,
            asyncDeterringWrap,
            emailRef,
            passwordRef,
            disableAutoLoginRef,
            errorMsgRef,
            PASSWORD_MAX_LENGTH,
            passwordToggle,
            switchPasswordFieldType,
            login,
            routeNames,
            isDisp,
        };
    },
});
