
import { defineComponent, inject, reactive, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { CognitoItem, CognitoItemKey } from "@/modules/cognitoItem";
import { Field, Form } from "vee-validate";
import { checkEmail, checkPassword, checkPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import LinkageSignupProgress from "@/components/Linkage/LinkageSignupProgress.vue";
import { loginStatus } from "@/modules/loginStatus";
import { reEncodeUriComponent } from "@/commons/commonUtility";
import LinkageTerms from "@/components/Linkage/LinkageTerms.vue";

export default defineComponent({
    name: "LinkageSignup",
    components: {
        Form,
        Field,
        LinkageSignupProgress,
        LinkageTerms
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames, emailDomains, isNeedCallbackWithToken } = LinkageItem.inject();

        const { cognitoInfo } = inject(CognitoItemKey) as CognitoItem;
        const errorMsgRef = ref("");

        const logoImageBaseURL = process.env.VUE_APP_BASE_URL + "/img/mypage/";

        const schema = Yup.object().shape({
            email: checkEmail,
            password: checkPassword,
            passwordRetype: checkPasswordRetype
        });

        const passwordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const passwordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * 登録ボタン押下時処理
         * @async
         */
        const register = async () => {
            try {
                let clientMetadata = {};
                if (!isNeedCallbackWithToken()) {
                    clientMetadata = {
                        LinkageName: linkageInfo?.pathName
                    };
                } else {
                    clientMetadata = {
                        LinkageName: linkageInfo?.pathName,
                        CodeChallenge: linkageInfo?.codeChallenge,
                        CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                    };
                }

                // Cognito登録
                const signupResponse = await sidClient.signUp({
                    type: "Email",
                    email: cognitoInfo.email,
                    password: cognitoInfo.password
                // }, {
                //     LinkageName: linkageInfo?.pathName,
                //     CodeChallenge: linkageInfo?.codeChallenge,
                //     CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                // });
                }, clientMetadata);

                // ユーザ名を取得
                cognitoInfo.userName = signupResponse.user.getUsername();

                if (signupResponse.userConfirmed) {
                    // 念の為サインアウト
                    await sidClient.signOut();
                    loginStatus.value = false;

                    // 既にユーザ確認済。通常はありえない
                    await sidClient.signIn(cognitoInfo.userName, cognitoInfo.password, true);
                    await router.push({ name: `${routeNames.SIGNUP_PROFILE}Child` });
                } else {
                    // ユーザ未確認
                    await router.push({ name: routeNames.SIGNUP_CODE });
                }
            } catch (e) {
                if (e.code === "UsernameExistsException") {
                    // 既にメールアドレスが登録されている場合
                    errorMsgRef.value = "メールアドレスは既に登録されています。";
                } else if (e.code === "UserLambdaValidationException") {
                    // 登録が許可されていない場合
                    errorMsgRef.value = "このメールアドレスは登録できません。";
                } else {
                    errorMsgRef.value = "ユーザ登録に失敗しました。後ほど再度お試し下さい。";
                }
            }
        };

        return {
            store,
            isDeterringRef,
            asyncDeterringWrap,
            cognitoInfo,
            errorMsgRef,
            schema,
            logoImageBaseURL,
            PASSWORD_MAX_LENGTH,
            passwordToggle,
            passwordRetypeToggle,
            switchPasswordFieldType,
            register,
            routeNames,
            emailDomains,
        };
    },
});
