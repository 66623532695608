
import { useRoute, useRouter } from "vue-router";
import { computed, defineComponent, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { BaseInfo, ShippingInfo, SiteInfo, UserBase, UserVJump } from "@/commons/interfaces";
import { MEMBER_AGREE_INFO_VERSION } from "@/commons/constants";
import moment from "moment";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed, isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "VJumpConfirm",
    props: {
        // 戻るボタン押下時の遷移先
        prevRoute: { type: String, required: true },
        // 登録・更新ボタン押下時の遷移先(nullの場合はトークンコールバックする)
        nextRoute: { type: String },
    },
    setup(props) {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap, setNotRelease } = useDeterring();
        const { linkageInfo, fetchToken, routeNames, siteUniqApiPath, isNeedCallbackWithToken, siteDefaultCallbackUrl } = LinkageItem.inject();

        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const userBase = userBaseItem.userData as UserBase;
        const userVJump = siteUniqItem.userData as UserVJump;
        const errorMsgRef = ref("");
        const isDispErrorMsgRef = ref(false);
        const nickName = computed(() => userBase.commonAttrInfo?.nickName?.replaceAll("&lt;", "<").replaceAll("&gt;", ">") || "");

        const date = computed(() => {
            const m = moment(userBase.commonAttrInfo.birthday);
            return { year: m.year(), month: m.month() + 1, day: m.date() };
        });
        // 初回サービス登録判定
        const firstServiceReg = route.name === `${routeNames.LOGIN_PROFILE_CONFIRM}Child` && !isSiteRegistered(linkageInfo.linkageId, userBase);
        // 会員移行後初回ログイン判定（基本属性情報：存在する+siteInfoにVジャンプの登録が無い、サイト固有情報：存在する）
        const firstLoginAfterMgn = userBase.identityId && userVJump.identityId && !isSiteRegistered(linkageInfo.linkageId, userBase);

        /**
         * 送付先情報設定
         * @param baseInfo 登録者情報
         * return shippingInfo
         */
        const setShippingInfo = (baseInfo: BaseInfo) => {
            const shippingInfo = new ShippingInfo();
            shippingInfo.lastName = baseInfo.lastName;
            shippingInfo.firstName = baseInfo.firstName;
            shippingInfo.lastKana = baseInfo.lastKana;
            shippingInfo.firstKana = baseInfo.firstKana;
            shippingInfo.zipCode = baseInfo.zipCode;
            shippingInfo.prefecture = baseInfo.prefecture;
            shippingInfo.address = baseInfo.address;
            shippingInfo.building = baseInfo.building;
            shippingInfo.tel = baseInfo.tel;
            return shippingInfo;
        };

        const upsertUserBase = async () => {

            const today = moment(new Date()).format("YYYY-MM-DD");

            // 送付先情報設定
            userBase.shippingInfo[0] = setShippingInfo(userBase.baseInfo);

            // 連携情報登録有無を確認
            const registeredSite = userBase.siteInfo.find(site => site.enable && site.siteLinkageId === linkageInfo.linkageId);
            if (!registeredSite) {
                // 対象サイト以外のリスト
                const newSites = userBase.siteInfo.filter(site => site.siteLinkageId !== linkageInfo.linkageId);
                // 対象サイトの情報の登録情報を作成
                const addSite: SiteInfo = {
                    siteLinkageId: linkageInfo.linkageId,
                    enable: true,
                    regDate: moment(new Date()).format("YYYY-MM-DD"),
                    leaveDate: null
                };
                newSites.push(addSite);
                userBase.siteInfo = newSites;
            }

            // 会員規約合意情報の設定
            userBase.memberAgreeInfo.version = MEMBER_AGREE_INFO_VERSION;
            userBase.memberAgreeInfo.regDate = today;

            // 要求用にオブジェクトをコピー
            const reqUserInfo = Object.assign({}, userBase);
            // ニックネームをエスケープ
            reqUserInfo.commonAttrInfo.nickName = reqUserInfo?.commonAttrInfo?.nickName?.replaceAll("<", "&lt;").replaceAll(">", "&gt;") || "";

            // 新規登録or更新
            const payload = JSON.parse(JSON.stringify(reqUserInfo));
            if (!userBase.identityId) {
                await sidClient.createUserAttr(payload);
            } else {
                await sidClient.updateUserAttr(payload);
            }
        };

        const upsertSiteUniq = async () => {
            // 要求用にオブジェクトをコピー
            const reqVJumpInfo = Object.assign({}, userVJump);

            // サイト固有情報登録or更新
            if (!userVJump.identityId) {
                await sidClient.requestApi({
                    method: "POST",
                    path: siteUniqApiPath,
                    body: reqVJumpInfo
                });
            } else {
                await sidClient.requestApi({
                    method: "PUT",
                    path: siteUniqApiPath,
                    body: reqVJumpInfo
                });
            }
        };

        const upsert = async () => {

            try {
                // 基本属性情報、サイト固有情報登録(パラレル)
                const results = await Promise.allSettled([
                    upsertUserBase(),
                    upsertSiteUniq()
                ]);
                const rejected = results.find(res => res.status === "rejected") as PromiseRejectedResult | undefined;
                if (rejected) {
                    throw rejected.reason;
                }
                userBaseItem.removeStorage();
                siteUniqItem.removeStorage();
            } catch (e) {
                if (isAuthFailed(e)) {
                    if (linkageInfo.email) {
                        if (route.name === routeNames.SIGNUP_CONFIRM) {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.SIGNUP_IDENTIFICATION, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        } else {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: routeNames.PROFILE }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        }
                    } else {
                        // ログイン画面へ遷移
                        await router.push({ name: routeNames.LOGIN, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    }
                    return;
                }
                // 前画面に戻る
                await Promise.allSettled([userBaseItem.fetchUserData(sidClient), siteUniqItem.fetchUserData(sidClient)])
                    .finally(async () => {
                        userBaseItem.userData.userName = siteUniqItem.userData.userName = (await sidClient.getUserData()).Username;
                        router.push({ name: props.prevRoute, params: { message: "会員情報の更新に失敗しました。再度お試し下さい。" } });
                    });
                return;
            }

            if (props.nextRoute) {
                // 完了画面へ遷移
                await router.push({ name: props.nextRoute });
            } else if (!isNeedCallbackWithToken()) {
                window.location.href = siteDefaultCallbackUrl as string;
            } else {
                try {
                    await fetchToken(sidClient);
                    setNotRelease();
                    window.location.href = linkageInfo.callbackUrlWithToken as string;
                } catch (e) {
                    await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
                }
            }
        };

        return {
            isDeterringRef,
            asyncDeterringWrap,
            userBase,
            userVJump,
            date,
            nickName,
            errorMsgRef,
            isDispErrorMsgRef,
            upsert,
            linkageInfo,
            firstServiceReg,
            firstLoginAfterMgn,
            routeNames,
            router
        };
    },
});
