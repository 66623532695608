import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, CommonAttrInfo, LinkageUserData, ManageInfo, MemberAgreementInfo, ShippingInfo, SiteInfo, UserBase, UserVJump } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import VJumpProfile from "@/components/Linkage/Sites/VJumpProfile.vue";
import VJumpConfirm from "@/components/Linkage/Sites/VJumpConfirm.vue";
import VJumpLoginCustom from "@/components/Linkage/Sites/VJumpLoginCustom.vue";

// Vジャンプ関連定数定義
/** 連携ID */
export const VJUMP_LINKAGE_ID = 11;
/** 連携名称 */
export const VJUMP_LINKAGE_NAME = "VJump";
/** 連携パス */
export const VJUMP_PATH = VJUMP_LINKAGE_NAME.toLowerCase();
/** サイト固有情報APIパス */
export const VJUMP_SITE_UNIQ_API_PATH = "/vjump/me";
/** デフォルトコールバックURL */
export const VJUMP_DEFAULT_CALLBACK_URL = process.env.VUE_APP_VJUMP_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const VJUMP_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_VJUMP_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const VJUMP_SERVICE_TERM_URL = process.env.VUE_APP_VJUMP_SERVICE_TERM_URL;
/** メールアドレスドメイン */
export const VJUMP_EMAIL_DOMAINS = [
    "vjump.shueisha.co.jp",
];
/** 基本属性情報入力 */
export const VJUMP_PROFILE_COMPONENT = VJumpProfile;
/** 基本属性情報確認 */
export const VJUMP_CONFIRM_COMPONENT = VJumpConfirm;
/** コールバックURLの任意指定 */
export const VJUMP_IS_CALLBACK_OPTIONAL = true;

/**
 * Vジャンプ連携画面用基本属性情報管理クラス
 */
export class VJumpUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("VJumpUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        // if (!this.data.baseInfo.firstName) return false;
        // if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        // if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        // if (!this.data.baseInfo.tel) return false;
        // 属性情報
        // if (!this.data.commonAttrInfo.gender) return false;
        // if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return VJumpUserBase.injectionKey;
    }

}

/**
 * Vジャンプ連携画面用サイト固有情報管理クラス
 */
export class VJumpSiteUniq implements LinkageUserData<UserVJump> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserVJump>>> = Symbol("UserVJump");

    /** Vジャンプサイト固有情報 */
    public readonly data: UserVJump = reactive(new UserVJump());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserVJump());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserVJump {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserVJump): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.isSendMailMagazine = obj.isSendMailMagazine;
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: VJUMP_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserVJump();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (this.data.isSendMailMagazine === null || this.data.isSendMailMagazine === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserVJump>>> {
        return VJumpSiteUniq.injectionKey;
    }
}