
import { useRouter } from "vue-router";
import { Ref, defineComponent, inject, onMounted, ref, watchEffect } from "vue";
import { ImageItem, ImageItemKey } from "@/modules/imageInfo";
import { PREFECTURE_LIST } from "@/commons/constants";
import { Form, Field } from "vee-validate";
import { checkAddressNotReqd, checkAgreement, checkBirthdayIsReqd, checkBloodTypeNotReqd, checkBuildingNotReqd, checkFirstKanaNotReqd, checkFirstNameNotReqd, checkGenderIsReqd, checkHasChildNotReqd, checkIsMarriedNotReqd, checkLastKanaNotReqd, checkLastNameNotReqd, checkNicknameIsReqd, checkPrefectureIsReqd, checkTelNotReqd, checkZipCodeIsReqd } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { useSidClient } from "@/plugins/auth";
import moment from "moment";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { useDateForm } from "@/commons/dateForm";
import { UserBaseItem } from "@/modules/userBaseItem";
import { UserBase } from "@/commons/interfaces";

export default defineComponent({
    name: "SignupProfile",
    components: {
        Form,
        Field
    },
    setup() {
        const router = useRouter();
        const sidClient = useSidClient();
        const userBaseItem = UserBaseItem.inject();
        const userBase = userBaseItem.userInfo as UserBase;
        const agreement = userBaseItem.agreement as Ref<boolean>;
        const { image, imageErrorMsgRef, isDeleteRef, fileUploadRef, setImage, operationImage } = inject(ImageItemKey) as ImageItem;
        const { setDate, date, years, months, dates, birthday } = useDateForm();
        const prefectureList = PREFECTURE_LIST;
        const formRef = ref<any>();

        // 登録時（誕生日未設定時）の初期選択値
        setDate(moment(new Date()).format("YYYY-M-D"));

        const schema = Yup.object().shape({
            lastName: checkLastNameNotReqd,
            firstName: checkFirstNameNotReqd,
            lastKana: checkLastKanaNotReqd,
            firstKana: checkFirstKanaNotReqd,
            nickname: checkNicknameIsReqd,
            gender: checkGenderIsReqd,
            birthday: checkBirthdayIsReqd,
            isMarried: checkIsMarriedNotReqd,
            hasChild: checkHasChildNotReqd,
            bloodType: checkBloodTypeNotReqd,
            zipCode: checkZipCodeIsReqd,
            prefecture: checkPrefectureIsReqd,
            address: checkAddressNotReqd,
            building: checkBuildingNotReqd,
            tel: checkTelNotReqd,
            agreement: checkAgreement
        });

        /**
         * 住所自動入力
         */
        const addressAutoFill = () => {
            const zipCode = userBase.baseInfo.zipCode ? userBase.baseInfo.zipCode : undefined;
            new YubinBangoCore(zipCode, (addr: any)=> {
                userBase.baseInfo.prefecture = addr.region; // 都道府県
                userBase.baseInfo.address = addr.locality; // 市区町村
                userBase.baseInfo.address += addr.street; // 町域
            });
        };

        /**
         * 登録ボタン押下時処理
         */
        const register = () => {
            router.push({ name: "SignupConfirm" });
        };

        const birthdayValidate = () => {
            formRef.value.validateField("birthday");
        };

        onMounted(async () => {
            try {
                const userData = await sidClient.getUserData();
                const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                userBase.baseInfo.email = email;
            } catch (e) {
                router.push({ name: "Login" });
                return;
            }

            watchEffect(() => {
                isDeleteRef.value = image.path ? true : false;

                userBase.commonAttrInfo.nickName = (typeof(userBase.commonAttrInfo.nickName) === "string") ? userBase.commonAttrInfo.nickName.replaceAll("&lt;","<").replaceAll("&gt;",">") : userBase.commonAttrInfo.nickName;

                const tmpDate = moment(`${date.year}-${date.month}-${date.day}`, "YYYY-M-D", true);
                userBase.commonAttrInfo.birthday = tmpDate.isValid() ? tmpDate.format("YYYY-MM-DD") : null;
            });
        });

        return {
            userBase,
            agreement,
            image,
            date,
            birthday,
            years,
            months,
            dates,
            prefectureList,
            fileUploadRef,
            isDeleteRef,
            imageErrorMsgRef,
            schema,
            formRef,            
            setImage,
            operationImage,
            addressAutoFill,
            register,
            birthdayValidate
        };
    },
});
