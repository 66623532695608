
import { useStore } from "@/store";
import { computed, defineComponent, onMounted } from "vue";
import { UserBaseItem } from "@/modules/userBaseItem";
import { useRouter } from "vue-router";
import { getSiteRegistration } from "@/commons/commonUtility";
import { LinkageChild, SiteLinkage } from "@/commons/interfaces";
import { useSidClient } from "@/plugins/auth";

export default defineComponent({
    name: "MypageTop",
    setup() {
        const store = useStore();
        const router = useRouter();
        const sidClient = useSidClient();
        const { registeredSiteList, notRegisteredSiteList } = getSiteRegistration(store.state.siteLinkage, store.state.userInfo);
        const userBaseItem = UserBaseItem.inject();
        const nickName = computed(() => (typeof (store.state.userInfo.commonAttrInfo.nickName) === "string") ? store.state.userInfo.commonAttrInfo.nickName.replaceAll("&lt;", "<").replaceAll("&gt;", ">") : store.state.userInfo.commonAttrInfo.nickName);
        const logoImageBaseURL = process.env.VUE_APP_BASE_URL + "/img/mypage/";
        onMounted(async () => {
            try {
                // 基本属性情報再取得して保持
                await store.dispatch("fetchUserInfo");
                userBaseItem.setUserInfo(store.state.userInfo);
            }
            catch (e) {
                // 特に何もしない
            }
        });

        const moveToWithdrawal = async (site: SiteLinkage, child?: LinkageChild) => {
            const checkSite = child || site;
            if (checkSite.is_close || checkSite.is_stand_alone) {
                // ポータル内で退会処理を行う場合は、退会処理画面へ遷移
                await router.push({ name: "QuitService", query: { siteLinkageId: site.link_id } });
                return;
            }
            // それ以外は外部サイト画面を開く
            window.open(checkSite.withdrawal_url as string, "_blank");
        };

        const moveToRegister = async (site: SiteLinkage, child?: LinkageChild) => {
            const checkSite = child || site;
            if (checkSite.is_stand_alone) {
                // スタンドアロンサイトの場合は認証を引き継ぎつつ連携画面へ遷移
                const urlObj = new URL(checkSite.login_url as string);
                urlObj.searchParams.set("callback", window.location.href);
                urlObj.searchParams.set("email", userBaseItem.userInfo.baseInfo.email as string);
                urlObj.searchParams.set("keepLogin", sidClient.clientConfig.authInfo.clientId);
                window.location.href = urlObj.toString();
                return;
            }
            window.open(checkSite.login_url as string, "_blank");
        };

        return {
            store,
            nickName,
            logoImageBaseURL,
            registeredSiteList,
            notRegisteredSiteList,
            moveToWithdrawal,
            moveToRegister,
        };
    },
});
